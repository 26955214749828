import React from "react"
import OfertaSezonowaPodwojna from "../OfertaSezonowaPodwojna"
import Snowfall from "react-snowfall"

const FerieOferty = () => (
  <>
    {/*<p>Zimowe warsztaty stacjonarne odbędą się w terminie 22-26 stycznia 2024. Szczegóły już wkrótce</p>*/}
    {/*<p>Zimowe warszaty stacjonarne odbędą się w terminie 22-26 stycznia 2024.</p>*/}
    <OfertaSezonowaPodwojna
    title={""}
    offers={[{
      name: "Ferie", src: require("../../images/oferty/ferie 2023.png"),
      description: "FERIE 2024  22 - 26 stycznia",
      subdescription: "",
      pdf: require("../../images/oferty/Ferie 2024_compressed.pdf"),
    }
      ,
      // {
      //   name: "Dance, Art & Fun", src: require("../../images/oferty/Acro, dance & fun - plakat (3).png"),
      //   description: "FERIE 2022 turnus 2 - Ursynów 07-11.02", subdescription: "",
      //   pdf: require("../../images/oferty/Acro, dance & fun - plakat (1).pdf"),
      // },
    ]}
    moreInfoFile={require("../../images/oferty/ZIMOWE WARSZTATY - FERIE 2024_compressed.pdf")}
    hideIkonkaDoKlikania={true}
  ></OfertaSezonowaPodwojna>
    <Snowfall />

  </>)

export default FerieOferty
